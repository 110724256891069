import Typography from '@material-ui/core/Typography';
import React from 'react';
import useStyles from './index.styles';

type FormSectionProps = {
  title: string;
};

export const FormSection: React.FC<FormSectionProps> = ({
  title,
  children,
}) => {
  const styles = useStyles();
  return (
    <div className={styles.fieldSection}>
      <Typography className={styles.sectionHeader} variant="h5">
        {title}
      </Typography>
      <div className={styles.divider} />
      {children}
    </div>
  );
};
