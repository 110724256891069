import { SvgIconTypeMap } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Link } from '@reach/router';
import React, { HTMLAttributes } from 'react';
import useStyles from './index.styles';

type NavButtonProps = {
  active?: boolean;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  label: string;
  to: string;
} & HTMLAttributes<HTMLButtonElement>;

export const NavButton: React.FC<NavButtonProps> = ({
  active,
  Icon,
  label,
  to,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Link to={`/${to}`} style={{ textDecoration: 'none', flex: 1 }}>
      <CardActionArea className={styles.navButton} {...props} tabIndex={-1}>
        {<Icon className={styles.navIcon} />}
        {label}
      </CardActionArea>
    </Link>
  );
};
