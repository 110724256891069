// @flow
import { makeStyles } from '@material-ui/core/styles';

type InputStyleProps = {
  error?: string;
};

export default makeStyles((theme) => ({
  input: {
    width: '100%',
    display: 'block',
    flex: 1,
    backgroundColor: '#E2E2E2',
    border: 'none',
    borderRadius: 3,
    height: 45,
    fontSize: '1rem',
    paddingLeft: theme.spacing(2),

    '&:focus': {
      border: `2px solid ${theme.palette.secondary.main}`,
      outline: `none`,
    },
  },
  label: {
    display: 'block',
    fontSize: '1rem',
    fontWeight: 500,
  },
  tip: {
    display: 'block',
    fontWeight: 'normal',
    paddingBottom: theme.spacing(2),
    color: (props: InputStyleProps) =>
      props.error ? 'red' : 'rgba(0,0,0,0.5)',
  },
  optional: {
    color: theme.palette.grey[500],
  },
}));
