// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  colorField: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  colorPreview: {
    marginLeft: theme.spacing(1),
    width: 45,
    height: 45,
    minWidth: 45,
    borderRadius: 5,
  },
}));
