// @flow
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC } from 'react';
import useStyles from './index.styles';

type InitializingProps = {
  visible: boolean;
};

export const Initializing: FC<InitializingProps> = ({ visible }) => {
  const styles = useStyles();
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className={styles.container}
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'linear' }}
        >
          <CircularProgress color="secondary" />
          <Typography variant="h6" className={styles.label}>
            Checking Credentials
          </Typography>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
