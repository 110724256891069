import React from 'react';
import {
  DefaultToastContainer,
  ToastContainerProps,
} from 'react-toast-notifications';
import useStyles from './index.styles';

type ToastNotificationProps = {};

export const ToastNotification: React.FC<
  ToastNotificationProps & ToastContainerProps
> = ({ children, ...props }) => {
  const styles = useStyles();
  return (
    <div className={styles.toastContainer}>
      <DefaultToastContainer {...props}> {children} </DefaultToastContainer>
    </div>
  );
};
