// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: 0,
    zIndex: theme.zIndex.tooltip,
    width: '100vw',
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  navbar: {
    pointerEvents: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    width: 400,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 0,
      borderRadius: 0,
    },
  },
}));
