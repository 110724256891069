// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  switchBase: {
    '&$checked': {
      color: theme.palette.warning.light,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.warning.light,
    },
    '&$track': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  switchLabel: {
    color: '#FFF',
    fontWeight: 500,
  },
  track: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 1,
  },

  content: {
    maxWidth: 800,
    width: '100%',
    margin: 'auto',
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  formContainer: {
    minHeight: 400,
    backgroundColor: '#FFF',
    boxShadow: '0px 6px 35px rgba(0, 0, 0, 0.25)',
    borderRadius: 5,
    '& > form': {
      padding: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(3),
      },
    },
  },
  tab: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },

  checked: {},
}));
