import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SchoolIcon from '@material-ui/icons/School';
import React from 'react';
import { NavButton } from 'src/components/atoms/NavButton';
import { useStore } from 'src/stores';
import useStyles from './index.styles';

export const NavBar: React.FC = () => {
  const styles = useStyles();
  const { authStore } = useStore();
  return (
    <footer className={styles.container}>
      <div className={styles.navbar}>
        <NavButton to="" label="Schools" Icon={SchoolIcon} />
        {/* <NavButton to="editors" label="Editors" Icon={PersonIcon} /> */}
        <NavButton
          to="login"
          label="Logout"
          Icon={ExitToAppIcon}
          onClick={() => {
            authStore.logout();
          }}
        />
      </div>
    </footer>
  );
};
