// @flow
import { makeStyles } from '@material-ui/core/styles';

type UploadAreaStyleProps = {
  disabled?: boolean;
};
export default makeStyles((theme) => ({
  logo: {
    width: '100%',
  },
  label: {
    display: 'block',
    fontSize: '1rem',
    fontWeight: 500,
  },
  uploadContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    width: 128,
    height: 128,
    margin: 'auto',
    border: (props: UploadAreaStyleProps) =>
      props.disabled
        ? `1px dashed rgba(0,0,0,0.5)`
        : `2px dashed ${theme.palette.secondary.main}`,
    borderRadius: 5,
  },
  tip: {
    display: 'block',
    fontWeight: 'normal',
    paddingBottom: theme.spacing(2),
    color: 'rgba(0,0,0,0.5)',
  },
  uploadIcon: {
    color: (props: UploadAreaStyleProps) =>
      props.disabled ? 'rgba(0,0,0,0.5)' : theme.palette.secondary.main,
  },
  input: {
    cursor: (props: UploadAreaStyleProps) =>
      props.disabled ? 'not-allowed' : 'pointer',
    width: '100%',
    height: '100%',
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));
