import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#0A1A29',
    },
    secondary: {
      main: '#58A4DA',
    },
    text: {
      primary: '#0A1A29',
      secondary: '#888888',
    },
  },
});
