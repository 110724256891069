import { RouteComponentProps, useNavigate } from '@reach/router';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStore } from 'src/stores';
type AuthedRoutesProps = {};

export const AuthedRoutes: React.FC<
  AuthedRoutesProps & RouteComponentProps
> = observer(({ children }) => {
  const { authStore } = useStore();
  const nav = useNavigate();
  useEffect(() => {
    if (authStore.initialized && !authStore.user) {
      nav('/login');
    }
  }, [nav, authStore.user, authStore.initialized]);

  return authStore.user ? <> {children} </> : <div />;
});
