// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: theme.spacing(2),
    },
  },
}));
