// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  fieldSection: {
    width: '100%',
    marginBottom: theme.spacing(4),
    '& > div': {
      marginBottom: theme.spacing(4),
    },
  },
  sectionHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    fontWeight: 500,
  },
  divider: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    height: 1,
    width: '100%',
  },
}));
