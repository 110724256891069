// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  fieldRow: {
    display: 'flex',
    '& > div': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > div:last-of-type': {
      flex: 1,
      marginRight: theme.spacing(0),
    },
  },
}));
