import theme from 'src/theme';
import { SchoolConfig, SchoolDocument } from 'src/types/schools';

type CreateSchoolFactory = (
  id: string,
  name: string,
  subdomain: string,
  creatorId: string
) => SchoolDocument;

export const createSchoolDocument: CreateSchoolFactory = (
  id,
  name,
  subdomain,
  creatorId
) => {
  const liveConfig: SchoolConfig = {
    features: {
      content_menu: true,
      custom_tour: true,
      off_campus_locations: true,
      hide_logo: false,
    },
    location: {
      lat: 24,
      lng: 24,
    },
    name,
    sub_domain: subdomain,
    urls: {
      logo_url: '',
      redirect_url: '',
      wordpress_api_url: '',
      wordpress_content_url: '',
      wordpress_contact_page_url: '',
      post_tour_survey_url: '',
      pre_tour_form_url: '',
    },
    colors: {
      primary: theme.palette.primary.main,
      secondary: theme.palette.secondary.main,
      splashBackgroundColor: '',
    },
  };
  return {
    id,
    liveConfig,
    draftConfig: liveConfig,
    editors: {
      [creatorId]: true,
    },
  };
};
