// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  navButton: {
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'column',
    height: 75,
    color: 'rgba(255,255,255,0.8)',
    fontWeight: 500,
  },
  navIcon: {
    marginBottom: theme.spacing(1),
    color: '#FFF',
  },
}));
