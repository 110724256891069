import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { SchoolServices } from 'src/services/schools';
import { RootStore } from 'src/stores';
import { SchoolConfig, SchoolDocument } from 'src/types/schools';

export class SchoolStore {
  schools: School[] = [];
  loadError = '';
  createError = '';
  loading = false;
  creating = false;
  rootStore: RootStore;
  services: SchoolServices;

  constructor(rootStore: RootStore, services: SchoolServices) {
    makeAutoObservable(this, {
      rootStore: false,
      services: false,
    });
    this.rootStore = rootStore;
    this.services = services;
  }

  async loadSchools() {
    try {
      this.loading = true;
      if (!this.rootStore.authStore.user)
        throw new Error('cannot fetch schools while user is not authenticated');
      const schools = await this.services.getSchools(
        this.rootStore.authStore.user.uid
      );
      console.log(schools);
      runInAction(() => {
        this.schools = schools
          .map((school) => new School(school, this))
          .sort((a, b) =>
            a.data.liveConfig.name < b.data.liveConfig.name ? -1 : 1
          );
      });
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.loadError = err.message;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async createSchool(name: string, subdomain: string) {
    try {
      this.creating = true;
      const school = await this.services.createSchool(
        name,
        subdomain.toLowerCase(),
        this.rootStore.authStore.user!.uid
      );
      runInAction(() => {
        this.schools = this.schools.concat([new School(school, this)]);
      });
    } catch (err) {
      console.error(err.message);
      runInAction(() => {
        this.createError = err.message;
      });
    } finally {
      runInAction(() => {
        this.creating = false;
      });
    }
  }
}

export class School {
  schoolStore: SchoolStore;
  data: SchoolDocument;
  updating = false;
  updateError = '';
  uploadError = '';
  uploading = false;

  constructor(school: SchoolDocument, schoolStore: SchoolStore) {
    makeAutoObservable(this, {
      schoolStore: false,
    });
    this.schoolStore = schoolStore;
    this.data = school;
    schoolStore.services.watchSchool(school.id, (school) => {
      this.data = school;
    });
  }

  async uploadLogo(isLive: boolean, logo: File, color: string) {
    this.uploading = true;
    try {
      const subdomain = isLive
        ? this.data.draftConfig.sub_domain
        : this.data.liveConfig.sub_domain;

      await this.schoolStore.services.updateAssets(
        subdomain,
        logo,
        color,
        isLive
      );

      runInAction(() => {
        this.uploading = false;
      });
    } catch (err) {
      throw new Error(err);
    } finally {
      runInAction(() => {
        this.uploading = false;
      });
    }
  }
  async update(config: SchoolConfig, isLive: boolean) {
    this.updating = true;
    const plainConfig = toJS(config);
    const plainData = toJS(this.data);
    try {
      const updatedData = isLive
        ? {
            ...plainData,
            liveConfig: { ...plainConfig },
          }
        : {
            ...plainData,
            draftConfig: { ...plainConfig },
          };
      await this.schoolStore.services.updateSchool(updatedData);
    } catch (err) {
      runInAction(() => {
        this.updateError = err.message;
      });
    } finally {
      runInAction(() => {
        this.updating = false;
      });
    }
  }

  async goLive() {
    this.updating = true;
    try {
      const plainData = toJS(this.data);
      plainData.liveConfig = plainData.draftConfig;
      await this.schoolStore.services.updateSchool(plainData);
      await this.schoolStore.services.copyAssetsLive(
        plainData.draftConfig.sub_domain
      );
    } catch (err) {
      this.updateError = err.message;
    } finally {
      runInAction(() => {
        this.updating = false;
      });
    }
  }
}
