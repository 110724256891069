import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormActions } from 'src/components/molecules/FormActions';
import { FormRow } from 'src/components/molecules/FormRow';
import { FormSection } from 'src/components/molecules/FormSection';
import { TextInput } from 'src/components/molecules/TextInput';
import { UploadArea } from 'src/components/molecules/UploadArea';
import { SchoolConfig } from 'src/types/schools';
import { sanitizeFormData, updateFieldsInObject } from 'src/utils/objects';
import schema from './index.schema';
import useStyles from './index.styles';

interface FormInputs {
  primary: string;
  secondary: string;
}

type BrandingFormProps = {
  config: SchoolConfig;
  logoUrl: string;
  onSave: (data: SchoolConfig) => Promise<void>;
  onUploadAssets: (file: File, splashBackgroundColor: string) => Promise<void>;
};

export const BrandingForm: React.FC<BrandingFormProps> = ({
  config,
  logoUrl,
  onSave,
  onUploadAssets,
}) => {
  const styles = useStyles();
  const [saving, setSaving] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<File | undefined>();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
    errors,
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: toFormState(config),
  });

  useEffect(() => {
    console.log('resetting');
    reset(toFormState(config));
  }, [reset, config]);

  if (!config) return <div />;

  const onSubmit = async (data: FormInputs) => {
    setSaving(true);
    const sanitizedData = sanitizeFormData(data);
    const updatedConfig = updateFieldsInObject(
      sanitizedData,
      config
    ) as SchoolConfig;
    await onSave(updatedConfig);
    if (uploadedImage) {
      await onUploadAssets(uploadedImage!, watch('splashBackgroundColor'));
    }
    setSaving(false);
  };

  const isFormClean = !isDirty && !uploadedImage;

  return (
    <form onSubmit={handleSubmit(onSubmit, (errs) => console.log(errs))}>
      <FormSection title="School Colors">
        <FormRow>
          <Controller
            control={control}
            name="primary"
            render={(props) => (
              <div className={styles.colorField}>
                <TextInput
                  placeholder="#000000"
                  label="Primary Color"
                  tip="This is used as a background color"
                  error={errors.primary?.message}
                  {...props}
                />
                <div
                  className={styles.colorPreview}
                  style={{ backgroundColor: watch('primary') }}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="secondary"
            render={(props) => (
              <div className={styles.colorField}>
                <TextInput
                  placeholder="#000000"
                  label="Secondary Color"
                  tip="This is used as an accent color"
                  error={errors.secondary?.message}
                  {...props}
                />
                <div
                  className={styles.colorPreview}
                  style={{ backgroundColor: watch('secondary') }}
                />
              </div>
            )}
          />
        </FormRow>
      </FormSection>
      <FormSection title="Icons &amp; Assets">
        {/* <Controller
          control={control}
          name="splashBackgroundColor"
          render={(props) => (
            <div className={styles.colorField}>
              <TextInput
                placeholder="#000000"
                label="Splash Background Color"
                tip="Splash images are what show when you first open an app. Choose a background color to use for splash images. Note that the logo will show on top of this color."
                error={errors.splashBackgroundColor?.message}
                {...props}
              />
              <div
                className={styles.colorPreview}
                style={{ backgroundColor: watch('splashBackgroundColor') }}
              />
            </div>
          )}
        /> */}
        <UploadArea
          src={logoUrl}
          id="school-logo"
          label="School Logo"
          loading={saving}
          onChange={setUploadedImage}
          tip="PWA icons will be generated based on it. File must be an SVG and have square dimensions."
        />
      </FormSection>
      <FormActions
        onReset={() => reset(toFormState(config))}
        disabled={
          saving || !!Object.values(errors).find(Boolean) || isFormClean
        }
        loading={saving}
      />
    </form>
  );
};

type ToFormState = (config?: SchoolConfig) => FormInputs | undefined;
const toFormState: ToFormState = (config) =>
  config && {
    primary: config.colors.primary,
    secondary: config.colors.secondary,
    splashBackgroundColor: config.colors.splashBackgroundColor || '',
  };
