import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormActions } from 'src/components/molecules/FormActions';
import { FormRow } from 'src/components/molecules/FormRow';
import { FormSection } from 'src/components/molecules/FormSection';
import { TextInput } from 'src/components/molecules/TextInput';
import schema from 'src/components/organisms/forms/BasicInfoForm/index.schema';
import { SchoolConfig } from 'src/types/schools';
import { sanitizeFormData, updateFieldsInObject } from 'src/utils/objects';

export interface FormInputs {
  name: string;
  lat: number;
  lng: number;
  sub_domain: string;
  redirect_url: string;
  wordpress_api_url: string;
  wordpress_content_url: string;
  wordpress_contact_page_url: string;
  post_tour_survey_url: string;
  pre_tour_form_url: string;
}

type BasicInfoFormProps = {
  config: SchoolConfig;
  onSave: (data: SchoolConfig) => Promise<void>;
};

export const BasicInfoForm: React.FC<BasicInfoFormProps> = ({
  onSave,
  config,
}) => {
  const [saving, setSaving] = useState(false);
  const {
    control,
    handleSubmit,
    reset,

    formState: { isDirty },
    errors,
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: toFormState(config),
  });

  const onSubmit = async (data: FormInputs) => {
    setSaving(true);
    const sanitizedData = sanitizeFormData(data);
    const updatedConfig = updateFieldsInObject(
      sanitizedData,
      config
    ) as SchoolConfig;
    await onSave(updatedConfig);
    setSaving(false);
  };

  useEffect(() => {
    if (config) {
      reset(toFormState(config));
    }
  }, [config, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSection title="Naming">
        <Controller
          control={control}
          name="name"
          render={(props) => (
            <TextInput
              tip="This name will display in the app's header"
              label="School Name"
              error={errors.name?.message}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="sub_domain"
          render={(props) => (
            <TextInput
              tip="The PWA uses the sub domain to determine which school to pull configurations for"
              label="Sub Domain"
              error={errors.sub_domain?.message}
              {...props}
            />
          )}
        />
      </FormSection>
      <FormSection title="Location">
        <FormRow>
          <Controller
            control={control}
            name="lat"
            render={(props) => (
              <TextInput
                label="Latitude"
                error={errors.lat?.message}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="lng"
            render={(props) => (
              <TextInput
                placeholder="-90"
                label="Longitude"
                error={errors.lng?.message}
                {...props}
              />
            )}
          />
        </FormRow>
      </FormSection>
      <FormSection title="Application URLs">
        <Controller
          control={control}
          name="redirect_url"
          render={(props) => (
            <TextInput
              tip="Users will be redirected to this url when accessing the app on non mobile devices"
              label="Desktop Site URL"
              error={errors.redirect_url?.message}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="wordpress_api_url"
          render={(props) => (
            <TextInput
              tip="The URL that the app will use to make all API calls"
              label="WordPress API URL"
              error={errors.wordpress_api_url?.message}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="wordpress_content_url"
          render={(props) => (
            <TextInput
              tip="Sometimes different from the API url, this url is used for the content menu."
              label="WordPress Content URL"
              error={errors.wordpress_content_url?.message}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="wordpress_contact_page_url"
          render={(props) => (
            <TextInput
              tip="The page url to embed in the contact tab."
              label="WordPress Contact Page URL"
              error={errors.wordpress_contact_page_url?.message}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="post_tour_survey_url"
          render={(props) => (
            <TextInput
              optional
              tip="Optionally redirect a user to some external url after finishing a tour"
              label="Tour Survey URL"
              error={errors.post_tour_survey_url?.message}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="pre_tour_form_url"
          render={(props) => (
            <TextInput
              optional
              tip="Optionally display an embedded external form (ex. salesforce) that screens users for tour use eligibility on their first visit."
              label="Pre-Tour Form URL"
              error={errors.pre_tour_form_url?.message}
              {...props}
            />
          )}
        />
      </FormSection>
      <FormActions
        onReset={() => reset(toFormState(config))}
        disabled={saving || !!Object.values(errors).find(Boolean) || !isDirty}
        loading={saving}
      />
    </form>
  );
};

type ToFormState = (config?: SchoolConfig) => FormInputs | undefined;
const toFormState: ToFormState = (config) =>
  config && {
    name: config.name,
    sub_domain: config.sub_domain,
    lat: config.location.lat,
    lng: config.location.lng,
    ...config.urls,
  };
