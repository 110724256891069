import React from 'react';
import { Button } from 'src/components/atoms/Button';
import useStyles from './index.styles';

type FormActionsProps = {
  onReset: () => void;
  loading?: boolean;
  disabled?: boolean;
};

export const FormActions: React.FC<FormActionsProps> = ({
  onReset,
  loading,
  disabled,
}) => {
  const styles = useStyles();
  return (
    <div className={styles.actions}>
      <Button onClick={onReset} size="large" color="secondary">
        Reset
      </Button>
      <Button
        disabled={disabled || loading}
        loading={loading}
        size="large"
        variant="contained"
        color="secondary"
        type="submit"
      >
        Save
      </Button>
    </div>
  );
};
