import { CircularProgress } from '@material-ui/core';
import MUIButton, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import useStyles from './index.styles';

type Props = {
  loading?: boolean;
};

export const Button: React.FC<ButtonProps & Props> = ({
  loading,
  children,
  ...props
}) => {
  const styles = useStyles();
  return (
    <MUIButton
      startIcon={
        loading && <CircularProgress size={25} className={styles.loader} />
      }
      classes={{
        contained: styles.contained,
        outlined: styles.outlined,
        startIcon: styles.startIcon,
        text: styles.text,
      }}
      {...props}
    >
      {loading ? '' : children}
    </MUIButton>
  );
};
