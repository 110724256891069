import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import {
  SchoolCard,
  SchoolCardSkeleton,
} from 'src/components/molecules/SchoolCard';
import { useStore } from 'src/stores';
import useStyles from './index.styles';

type SchoolListProps = {
  query?: string;
  resultsPerPage: number;
};

export const SchoolList: React.FC<SchoolListProps> = observer(
  ({ query, resultsPerPage }) => {
    const styles = useStyles();
    const { schoolStore } = useStore();

    // filters results
    const schools = useMemo(
      () =>
        schoolStore.schools
          .filter(
            (school) =>
              !query ||
              school.data.liveConfig.name
                .toLowerCase()
                .includes(query.toLowerCase())
          )
          .map((school) => school.data),
      [query, schoolStore.schools]
    );

    const renderSkeleton = useCallback(
      () =>
        new Array(resultsPerPage)
          .fill(0)
          .map((_val, index) => <SchoolCardSkeleton key={index} />),
      [resultsPerPage]
    );

    return (
      <ul className={styles.content}>
        {schoolStore.loading && renderSkeleton()}
        <AnimatePresence>
          {schools.map((school) => (
            <SchoolCard
              key={school.id}
              id={school.id}
              logo={school.liveConfig.urls.logo_url}
              name={school.liveConfig.name}
              url={
                school.liveConfig.sub_domain
                  ? `https://${school.liveConfig.sub_domain}.campusexperienceapp.com`
                  : 'none'
              }
              primaryColor={school.liveConfig.colors.primary}
              secondaryColor={school.liveConfig.colors.secondary}
            />
          ))}
        </AnimatePresence>
      </ul>
    );
  }
);
