import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button } from 'src/components/atoms/Button';
import { useStore } from 'src/stores';
import useStyles from './index.styles';

type CreateSchoolDialogProps = {
  onClose: () => void;
} & DialogProps;

export const CreateSchoolDialog: React.FC<CreateSchoolDialogProps> = observer(
  ({ onClose, ...dialogProps }) => {
    const styles = useStyles();
    const [name, setName] = useState('');
    const [subDomain, setSubDomain] = useState('');

    const { schoolStore } = useStore();

    const onCreate = async () => {
      await schoolStore.createSchool(
        name.trim(),
        subDomain.toLowerCase().trim()
      );
      onClose();
    };

    return (
      <Dialog onClose={onClose} {...dialogProps}>
        <DialogTitle id="form-dialog-title">New School</DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.disclaimer}>
            Refer to the{' '}
            <Link className={styles.instructionLink}>
              deployment documentation
            </Link>{' '}
            for configuration instructions.
          </DialogContentText>
          <TextField
            fullWidth
            autoFocus
            value={name}
            label="School Name"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextField
            fullWidth
            value={subDomain}
            label="Sub Domain"
            onChange={(e) => {
              setSubDomain(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button
            loading={schoolStore.creating}
            disabled={!name || !subDomain}
            size="large"
            variant="contained"
            color="secondary"
            onClick={onCreate}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
