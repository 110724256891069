import constate from 'constate';
import { useMemo } from 'react';
import defaultServices, { Services } from 'src/services';
import { AuthStore } from 'src/stores/domain/auth';
import { EditorStore } from 'src/stores/domain/editors';
import { SchoolStore } from 'src/stores/domain/schools';

export class RootStore {
  authStore: AuthStore;
  schoolStore: SchoolStore;
  editorStore: EditorStore;

  constructor(services: Services) {
    this.authStore = new AuthStore(this);
    this.schoolStore = new SchoolStore(this, services.schoolServices);
    this.editorStore = new EditorStore(this, services.editorServices);
  }
}

type UseStoreProps = {
  services?: Services;
};

const useStoreHook = ({ services }: UseStoreProps) => {
  const store = useMemo(() => new RootStore(services || defaultServices), [
    services,
  ]);
  return store;
};

export const [StoreProvider, useStore] = constate(useStoreHook);
