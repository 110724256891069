import { IconButton, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from '@reach/router';
import React, { HTMLAttributes, useState } from 'react';
import { ReactComponent as MomentousLogo } from 'src/assets/logo_white_no_text.svg';
import { Input } from 'src/components/atoms/Input';
import { useStyles } from './index.styles';
type HeaderProps = {
  isLive?: boolean;
  showSearch?: boolean;
  pageTitle: string;
};

type SearchProps = {
  searchProps?: HTMLAttributes<HTMLInputElement> & { value?: string };
};

export const Header: React.FC<HeaderProps & SearchProps> = ({
  isLive,
  showSearch,
  pageTitle,
  children,
  searchProps,
}) => {
  const [expandSearch, setExpandSearch] = useState(false);
  const styles = useStyles({ isLive });
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'));

  const revealSearchBar = !isPhone || (isPhone && expandSearch);
  const revealSearchIcon = isPhone && !expandSearch;

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {!expandSearch && (
            <>
              <Link to="/" className={styles.logoLink}>
                <MomentousLogo className={styles.logo} />
              </Link>
              <Typography variant="h5" className={styles.pageTitle}>
                {pageTitle}
              </Typography>
            </>
          )}
        </div>
        <div className={styles.headerRight}>
          {showSearch && (
            <>
              {revealSearchIcon && (
                <IconButton
                  className={styles.icon}
                  onClick={() => setExpandSearch(true)}
                >
                  <SearchIcon />
                </IconButton>
              )}
              {expandSearch && (
                <IconButton
                  className={styles.icon}
                  onClick={() => setExpandSearch(false)}
                >
                  <CloseIcon />
                </IconButton>
              )}
              {revealSearchBar && (
                <Input
                  options={{ delimiter: '' }}
                  placeholder="Search schools..."
                  className={styles.searchBar}
                  {...searchProps}
                />
              )}
            </>
          )}
          {!expandSearch && children}
        </div>
      </div>
      <div className={styles.liveWarning}>
        <Typography variant="subtitle2">
          Warning: editing live application
        </Typography>
      </div>
    </>
  );
};
