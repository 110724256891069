import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as Logo } from 'src/assets/logo_blue.svg';
import { Button } from 'src/components/atoms/Button';
import { useStore } from 'src/stores';
import useStyles from './index.styles';

export const Login: React.FC<RouteComponentProps> = observer(() => {
  const styles = useStyles();
  const { authStore } = useStore();
  const [email, setEmail] = useState('');
  const location = useLocation();
  const nav = useNavigate();

  const isVerifying = location.pathname === '/verify';

  // first step in login process: send login link to email
  const onSendLoginLink = useCallback(() => {
    authStore.sendLoginLink(email);
  }, [authStore, email]);

  // called once user successfully verifies email
  const onLogin = useCallback(() => {
    authStore.verifyEmail(email);
  }, [authStore, email]);

  // redirects to dashboard on successful login
  useEffect(() => {
    if (authStore.user) {
      nav('/');
    }
  }, [authStore.user, nav]);

  // checks if we have an email cached to make verification faster
  useEffect(() => {
    if (authStore.loginEmail) {
      setEmail(authStore.loginEmail);
    }
  }, [authStore.loginEmail, setEmail]);

  return (
    <div className={styles.pageContainer}>
      <Logo className={styles.logo} />
      <div className={styles.card}>
        <Typography variant="h5" color="textPrimary" className={styles.header}>
          {isVerifying ? 'Confirm Email' : 'Admin Login'}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {isVerifying
            ? 'Type your email and click login'
            : 'Campus Experience App'}
        </Typography>
        <TextField
          fullWidth
          autoFocus
          type="email"
          name="email"
          value={email}
          label="Email"
          className={styles.emailInput}
          onChange={(e) => {
            setEmail(e.target.value);
            if (authStore.error) authStore.error = '';
          }}
        />
        <Button
          fullWidth
          disabled={
            !email ||
            authStore.sendingEmailLink ||
            authStore.linkSent ||
            authStore.loggingIn
          }
          size="large"
          color="secondary"
          variant="contained"
          className={styles.loginButton}
          onClick={isVerifying ? onLogin : onSendLoginLink}
        >
          {isVerifying ? 'Go to Dashboard' : 'Send Login Link'}
        </Button>
        <Typography component="h1" variant="subtitle2" color="secondary">
          {authStore.linkSent && 'Login link sent'}
        </Typography>
        <Typography component="h1" variant="subtitle2" color="error">
          {authStore.error}
        </Typography>
      </div>
    </div>
  );
});
