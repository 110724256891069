// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    paddingLeft: 0,
    paddingTop: theme.spacing(6),
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(316px, 1fr))`,
    width: '100%',
    margin: 'auto',
    justifyItems: 'center',
    rowGap: `${theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
    },
  },
}));
