import Typography from '@material-ui/core/Typography';
import { CleaveOptions } from 'cleave.js/options';
import React, { HTMLAttributes } from 'react';
import useStyles from './index.styles';

type TextInputProps = {
  tip?: string;
  options?: CleaveOptions;
  defaultValue?: string;
  name: string;
  label: string;
  error?: string;
  optional?: boolean;
  // control: Control<Record<string, any>>;
} & HTMLAttributes<HTMLInputElement>;

export const TextInput: React.FC<TextInputProps> = React.forwardRef(
  (
    {
      tip,
      defaultValue = '',
      name,
      label,
      error,
      optional,
      options = defaultOptions,
      ...props
    },
    ref
  ) => {
    const styles = useStyles({ error });
    return (
      <div>
        <Typography
          className={styles.label}
          component="label"
          variant="h6"
          htmlFor={name}
        >
          {label}{' '}
          {optional && <span className={styles.optional}>(optional)</span>}
        </Typography>
        <Typography className={styles.tip} component="span" variant="subtitle2">
          {error || tip}
        </Typography>

        <input ref={ref as any} className={styles.input} id={name} {...props} />
      </div>
    );
  }
);

const defaultOptions: CleaveOptions = {
  delimiter: '',
};
