// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  input: {
    flex: 1,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontSize: 16,
    borderRadius: 5,
    border: 'none',
    outline: 'none',
    color: '#FFF',
    '&::placeholder': {
      color: 'rgba(255,255,255, 0.7)',
    },
    backgroundColor: 'rgba(255,255,255, 0.3)',
  },
}));
