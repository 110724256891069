import { editorServices, EditorServices } from 'src/services/editors';
import { schoolServices, SchoolServices } from 'src/services/schools';

export type Services = {
  schoolServices: SchoolServices;
  editorServices: EditorServices;
};

const defaultServices = {
  schoolServices,
  editorServices,
};
export default defaultServices;
