import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required(),
  lat: yup
    .number()
    .min(-90)
    .max(90)
    .required()
    .typeError('Latitude must be between -90 and 90'),
  lng: yup
    .number()
    .min(-90)
    .max(90)
    .required()
    .typeError('Longitude must be between -90 and 90'),
  sub_domain: yup.string().required('Please enter a sub domain'),
  redirect_url: yup.string().url().required('Please enter a valid url'),
  wordpress_api_url: yup.string().url().required('Please enter a valid url'),
  wordpress_content_url: yup
    .string()
    .url()
    .required('Please enter a valid url'),
  wordpress_contact_page_url: yup
    .string()
    .url()
    .required('Please enter a valid url'),
  post_tour_survey_url: yup.string().url(),
  pre_tour_survey_urL: yup.string().url(),
});

export default schema;
