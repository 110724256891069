import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import React, { ChangeEvent, useState } from 'react';
import { ReactComponent as UploadIcon } from 'src/assets/upload.svg';
import useStyles from './index.styles';

type UploadAreaProps = {
  id: string;
  label: string;
  tip: string;
  disabled?: boolean;
  src?: string;
  loading?: boolean;
  onChange: (file: File) => void;
};

export const UploadArea: React.FC<UploadAreaProps> = ({
  id,
  label,
  tip,
  onChange,
  loading,
  disabled,
  src,
}) => {
  const styles = useStyles({ disabled });
  const [imagePreview, setImagePreview] = useState('');
  const readImageFile = (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        setImagePreview(reader.result as string);
      },
      false
    );

    if (e.target.files?.length) {
      const file = e.target.files[0];
      onChange(file);
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <Typography
        className={styles.label}
        component="label"
        variant="h6"
        htmlFor={id}
      >
        {label}
      </Typography>
      <Typography className={styles.tip} component="span" variant="subtitle2">
        {tip}
      </Typography>
      <div
        className={styles.uploadContainer}
        style={imagePreview || src ? { border: 'none' } : undefined}
      >
        <input
          id={id}
          disabled={disabled}
          className={styles.input}
          type="file"
          accept=".svg"
          onChange={readImageFile}
        />

        {loading ? (
          <CircularProgress color="secondary" />
        ) : src || imagePreview ? (
          <img className={styles.logo} src={imagePreview || src} alt="logo" />
        ) : (
          <UploadIcon className={styles.uploadIcon} />
        )}
      </div>
    </>
  );
};
