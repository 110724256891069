import * as yup from 'yup';

const schema = yup.object().shape({
  primary: yup
    .string()
    .length(7, 'please enter a valid hex color')
    .required('please enter a valid hex color')
    .typeError('please enter a valid hex color'),
  secondary: yup
    .string()
    .length(7, 'please enter a valid hex color')
    .required('please enter a valid hex color')
    .typeError('please enter a valid hex color'),
});

export default schema;
