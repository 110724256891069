// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'fixed',
    backgroundColor: theme.palette.primary.main,
    top: 0,
    left: 0,
    zIndex: 500000,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.3s',
  },
  label: {
    color: '#FFF',
    marginTop: theme.spacing(2),
  },
}));
