import { makeStyles } from '@material-ui/core';

type ModeProps = {
  isLive?: boolean;
};

export const useStyles = makeStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(6)}px`,
    width: '100%',
    height: 60,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  headerLeft: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  headerRight: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: '#FFF',
    [theme.breakpoints.down('xs')]: {
      flex: 1,
    },
  },
  liveWarning: {
    position: 'sticky',
    top: 60,
    zIndex: theme.zIndex.modal,
    transition: 'opacity 0.2s',
    opacity: (props: ModeProps) => (props.isLive ? 1 : 0),
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.warning.light,
  },
  logo: {
    height: 30,
  },
  logoLink: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#FFF',
    fontWeight: 500,
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  icon: {
    color: '#FFF',
  },
  searchBar: {
    flex: 1,
  },
}));
