import { CardActionArea } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { Skeleton } from '@material-ui/lab';
import { Link as RouterLink } from '@reach/router';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import { ReactComponent as MomentousLogo } from 'src/assets/logo_white_no_text.svg';
import useStyles from './index.styles';

type SchoolCardProps = {
  logo?: string;
  name: string;
  url: string;
  id: string;
  primaryColor: string;
  secondaryColor: string;
};

export const SchoolCard: React.FC<SchoolCardProps> = ({
  name,
  logo,
  id,
  primaryColor,
  secondaryColor,
  url,
}) => {
  const styles = useStyles({ primaryColor, secondaryColor });
  return (
    <motion.li
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.card}
    >
      <div className={styles.cardContent}>
        {logo ? (
          <img src={logo} alt={`${name} logo`} className={styles.logo} />
        ) : (
          <MomentousLogo className={styles.logo} />
        )}

        <Typography variant="h5" className={styles.name}>
          {name}
        </Typography>
        <Link href={url}>
          <Typography variant="subtitle2" className={styles.url}>
            {url}
          </Typography>
        </Link>
      </div>
      <RouterLink to={`/edit/${id}`} style={{ textDecoration: 'none' }}>
        <CardActionArea className={styles.editButton} tabIndex={-1}>
          <Typography variant="subtitle1" className={styles.editButtonLabel}>
            Edit Configuration
          </Typography>
          <TrendingFlatIcon fontSize="large" />
        </CardActionArea>
      </RouterLink>
    </motion.li>
  );
};

export const SchoolCardSkeleton: React.FC = () => {
  const styles = useStyles();
  return (
    <li className={classnames(styles.skeletonBackground, styles.card)}>
      <div className={styles.cardContent}>
        <Skeleton
          variant="circle"
          width="50px"
          height="50px"
          className={styles.placeholder}
        />
        <Typography variant="h5" className={styles.name}>
          <Skeleton
            width="250px"
            height="25px"
            className={styles.placeholder}
          />
        </Typography>
        <Link href={`https://`}>
          <Typography variant="subtitle2" className={styles.url}>
            <Skeleton
              width="250px"
              height="15px"
              className={styles.placeholder}
            />
          </Typography>
        </Link>
      </div>
      <Skeleton className={classnames(styles.placeholder, styles.editButton)} />
    </li>
  );
};
