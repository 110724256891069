// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  addSchoolButton: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
}));
