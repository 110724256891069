import { RouteComponentProps, Router } from '@reach/router';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { AuthedRoutes } from 'src/components/atoms/AuthedRoutes';
import { Initializing } from 'src/components/molecules/Initializing';
import { NavBar } from 'src/components/molecules/NavBar';
import { Configuration } from 'src/pages/Configuration';
import { Login } from 'src/pages/Login';
import { Schools } from 'src/pages/Schools';
import { useStore } from 'src/stores';

const App: FC<RouteComponentProps> = observer(() => {
  const { authStore } = useStore();

  return (
    <div style={{ paddingBottom: 100 }}>
      <Initializing visible={!authStore.initialized} />
      <Router>
        <Login path="/login" />
        <Login path="/verify" />
        <AuthedRoutes path="/">
          <Schools path="/" />
          <Configuration path="/edit/:schoolId" />
        </AuthedRoutes>
      </Router>
      {authStore.user && <NavBar />}
    </div>
  );
});

export default App;
