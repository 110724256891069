// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  pageContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
      margin: 'auto',
    },
  },
  logo: {
    marginBottom: theme.spacing(4),
  },

  card: {
    width: 375,
    boxShadow: '-2px 4px 35px rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 5,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  header: {
    fontWeight: 500,
  },

  emailInput: {
    marginTop: theme.spacing(2),
  },
  loginButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: '#FFF',
  },
}));
