import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { SwitchInput } from 'src/components/molecules/SwitchInput';
import { BasicInfoForm } from 'src/components/organisms/forms/BasicInfoForm';
import { BrandingForm } from 'src/components/organisms/forms/BrandingForm';
import { FeaturesForm } from 'src/components/organisms/forms/FeaturesForm';
import { Header } from 'src/components/organisms/Header';
import { MergeChangesDialog } from 'src/components/organisms/modals/MergeChangesDialog';
import { useStore } from 'src/stores';
import { SchoolConfig } from 'src/types/schools';
import useStyles from './index.styles';

interface RouteProps extends RouteComponentProps {
  schoolId?: string;
}

type ConfigurationProps = {};

export const Configuration: React.FC<
  ConfigurationProps & RouteProps
> = observer(({ schoolId }) => {
  const { schoolStore } = useStore();
  const { addToast } = useToasts();

  const [isLive, setIsLive] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [openMergeDialog, setOpenMergeDialog] = useState(false);

  const styles = useStyles({ isLive });

  const school = schoolStore.schools.find(
    (school) => school.data.id === schoolId
  );

  const config = isLive ? school?.data.liveConfig : school?.data.draftConfig;

  const onSave = async (data: SchoolConfig) => {
    try {
      await school?.update(data, isLive);
      addToast('updated school configuration successfully', {
        appearance: 'success',
      });
    } catch (err) {
      addToast(err.message, { appearance: 'error' });
    }
  };

  const onUploadAssets = async (file: File, color: string) => {
    try {
      await school?.uploadLogo(isLive, file, color);
      addToast(
        `successfully generated PWA assets from color ${color} and file ${file.name}`,
        { appearance: 'success' }
      );
    } catch (err) {
      addToast(err.message, { appearance: 'error' });
    }
  };

  const renderForm = () => {
    if (!config) return '';
    switch (activeTab) {
      case 0:
        return <BasicInfoForm onSave={onSave} config={config} />;
      case 1:
        return <FeaturesForm onSave={onSave} config={config} />;
      case 2:
        return (
          <BrandingForm
            logoUrl={`${process.env.REACT_APP_STORAGE_URL}/${
              config.sub_domain
            }/${isLive ? 'liveConfig' : 'draftConfig'}/school_logo.svg`}
            onSave={onSave}
            onUploadAssets={onUploadAssets}
            config={config}
          />
        );
    }
  };

  const onModeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const switchToLive = e.target.checked;
    if (switchToLive) {
      setOpenMergeDialog(true);
    } else {
      setIsLive(switchToLive);
    }
  };

  const onConfirmMerge = async () => {
    await school?.goLive();
    setIsLive(true);
    setOpenMergeDialog(false);
  };

  useEffect(() => {
    if (school?.updateError)
      addToast(school?.updateError, { appearance: 'error' });
  }, [addToast, school?.updateError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header
        isLive={isLive}
        pageTitle={school?.data.liveConfig.sub_domain.toUpperCase() || ''}
      >
        <SwitchInput
          value={isLive}
          onChange={onModeChange}
          name="liveModeSwitch"
          label={isLive ? 'LIVE' : 'Edit'}
        />
      </Header>

      <div className={styles.content}>
        <Tabs
          variant="fullWidth"
          value={activeTab}
          onChange={(e, val) => setActiveTab(val)}
          aria-label="simple tabs example"
        >
          <Tab className={styles.tab} label="Basic Info" />
          <Tab className={styles.tab} label="Features" />
          <Tab className={styles.tab} label="Branding" />
        </Tabs>
        <div className={styles.formContainer}>{renderForm()}</div>
      </div>
      <MergeChangesDialog
        open={openMergeDialog}
        onConfirm={onConfirmMerge}
        onDeny={() => {
          setOpenMergeDialog(false);
        }}
        onClose={() => setOpenMergeDialog(false)}
      />
    </>
  );
});
