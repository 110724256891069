import { firestore } from 'src/config/firebase';
import { EditorDocument } from 'src/types/editors';

const getEditors = async () => {
  const snapshot = await firestore.collection('editors').get();
  return snapshot.docs.map(
    (doc) => ({ id: doc.id, ...doc.data() } as EditorDocument)
  );
};

type ChangeCallback = (editor: EditorDocument) => void;
const onEditorChange = (id: string, callback: ChangeCallback) => {
  firestore.doc(`editors/${id}`).onSnapshot((snap) => {
    callback(snap.data() as EditorDocument);
  });
};

export const editorServices = {
  getEditors,
  watchEditor: onEditorChange,
};

export type EditorServices = typeof editorServices;
