type GenericObject = { [key: string]: any };

export function updateFieldsInObject(
  fields: GenericObject,
  object: GenericObject
) {
  const copy = JSON.parse(JSON.stringify(object)) as GenericObject;
  const fieldKeys = Object.keys(fields);
  for (const [key] of Object.entries(copy)) {
    if (typeof copy[key] === 'object' && copy[key] !== null) {
      copy[key] = updateFieldsInObject(fields, copy[key]);
    } else {
      const matchingKey = fieldKeys.find((fieldKey) => fieldKey === key);
      if (matchingKey) {
        copy[key] = fields[matchingKey];
      }
    }
  }
  return copy;
}

export const sanitizeFormData = (data: Record<string, any>) => {
  const copy = { ...data };
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === 'string') copy[key] = data[key].trim();
  });
  return copy;
};
