import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

console.log(process.env);
const app = firebase.initializeApp(
  JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string)
);

export const auth = app.auth();
export const firestore = app.firestore();
export const storage = app.storage();
