import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button } from 'src/components/atoms/Button';
import useStyles from './index.styles';

type MergeChangesDialogProps = {
  onClose: () => void;
  onConfirm: () => Promise<void>;
  onDeny: () => void;
} & DialogProps;

export const MergeChangesDialog: React.FC<MergeChangesDialogProps> = observer(
  ({ onClose, onConfirm, onDeny, ...dialogProps }) => {
    const styles = useStyles();
    const [loading, setLoading] = useState(false);

    return (
      <Dialog onClose={onClose} {...dialogProps}>
        <DialogTitle id="form-dialog-title">Push Changes?</DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.disclaimer}>
            Are you sure wish want to commit changes to the live application?
            Make sure to double check the configuration in test mode before
            proceeding.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            onClick={() => {
              onDeny();
              onClose();
            }}
          >
            Not Yet
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            size="large"
            variant="contained"
            color="secondary"
            onClick={async () => {
              setLoading(true);
              await onConfirm();
              setLoading(false);
            }}
          >
            Push Live
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
