// @flow
import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  contained: {
    textTransform: 'none',
    color: '#FFF',
  },
  outlined: {
    textTransform: 'none',
    borderWidth: 2,
    '&:hover': {
      borderWidth: 2,
    },
  },
  text: {
    textTransform: 'none',
  },
  startIcon: {
    marginRight: theme.spacing(0.5),
  },
  loader: {
    color: theme.palette.grey[500],
  },
}));
