import { makeAutoObservable, runInAction } from 'mobx';
import { auth } from 'src/config/firebase';
import { RootStore } from 'src/stores';
import { SchoolPermissions } from 'src/types/editors';

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/verify'
      : `https://${window.location.hostname}/verify`,
  // This must be true.
  handleCodeInApp: true,
};

export class AuthStore {
  private _loginEmail = '';

  initialized = false;
  sendingEmailLink = false;
  linkSent = false;
  loggingIn = false;
  error = '';
  user: firebase.default.User | null = null;
  roles: SchoolPermissions = {};

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });

    this.rootStore = rootStore;
    auth.onAuthStateChanged((user) => {
      runInAction(() => {
        this.user = user;
        this.initialized = true;
        if (user) {
          rootStore.schoolStore.loadSchools();
        }
      });
    });
  }

  get loginEmail() {
    const cachedEmail = localStorage.getItem('email');
    return this._loginEmail || cachedEmail;
  }

  async sendLoginLink(email: string) {
    try {
      this.linkSent = false;
      this.sendingEmailLink = true;
      // if a user has no sign in methods attached, they must not exist
      const methods = await auth.fetchSignInMethodsForEmail(email);
      if (!methods.length) {
        throw new Error('this user does not exist');
      }
      await auth?.sendSignInLinkToEmail(email, actionCodeSettings);
      // save email locally so user doesn't have to re-enter it if on same browser
      localStorage.setItem('email', email);
      this._loginEmail = email;
      this.linkSent = true;
    } catch (err) {
      this.error = err.message;
    } finally {
      this.sendingEmailLink = false;
    }
  }

  async verifyEmail(email: string) {
    try {
      this.loggingIn = true;
      if (auth.isSignInWithEmailLink(window.location.href)) {
        const credential = await auth.signInWithEmailLink(
          email,
          window.location.href
        );

        this.user = credential.user;
      } else {
        throw new Error('this link is either not valid or already expired');
      }
    } catch (err) {
      this.error = err.message;
    } finally {
      this.loggingIn = false;
      this._loginEmail = '';
      localStorage.removeItem('email');
    }
  }

  logout() {
    auth.signOut();
  }
}
