import Switch, { SwitchProps } from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import useStyles from './index.styles';

type SwitchInputProps = {
  label: string | Node;
  tip?: string;
};

export const SwitchInput: React.FC<
  SwitchInputProps & SwitchProps
> = React.forwardRef(({ name, label, tip, value, ...props }, ref) => {
  const styles = useStyles();
  return (
    <div className={styles.switchContainer}>
      <Switch
        {...props}
        id={name}
        checked={value as boolean}
        classes={{
          switchBase: styles.switchBase,
          track: styles.track,
          checked: styles.checked,
        }}
        inputProps={{ name }}
        inputRef={ref}
      />
      <div>
        <Typography
          htmlFor={name}
          className={styles.label}
          component="label"
          variant="h6"
        >
          {label}
        </Typography>
        <Typography className={styles.tip} component="span" variant="subtitle2">
          {tip}
        </Typography>
      </div>
    </div>
  );
});
