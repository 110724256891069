import { auth, firestore, storage } from 'src/config/firebase';
import { SchoolDocument } from 'src/types/schools';
import { createSchoolDocument } from 'src/utils/factories';
const getSchools = async (userId: string) => {
  let snapshot;
  snapshot = await firestore.collection('schools').get();

  return snapshot.docs.map(
    (doc) => ({ id: doc.id, ...doc.data() } as SchoolDocument)
  );
};

const createSchool = async (
  name: string,
  subdomain: string,
  userId: string
) => {
  const doc = firestore.collection('schools').doc();
  const school = createSchoolDocument(doc.id, name, subdomain, userId);
  await doc.set(school, { merge: true });
  return school;
};

const updateSchool = async (school: Partial<SchoolDocument>) => {
  await firestore
    .collection('schools')
    .doc(school.id)
    .set(school, { merge: true });
};

const updateAssets = async (
  subdomain: string,
  asset: File,
  backgroundColor: string,
  isLive: boolean
) => {
  const storageRef = storage.ref(
    `${subdomain}/${isLive ? 'liveConfig' : 'draftConfig'}/school_logo.svg`
  );
  const renamedFile = new File([asset], 'school_logo');
  await storageRef.put(renamedFile, { contentType: 'image/svg+xml' });
  const token = await auth.currentUser!.getIdToken();
  await fetch(`${process.env.REACT_APP_API_URL}/assets/generate`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      authorization: token,
    },
    body: JSON.stringify({
      subdomain,
      backgroundColor,
      isLive,
    }),
  });
};

const copyAssetsLive = async (subdomain: string) => {
  const token = await auth.currentUser!.getIdToken();
  await fetch(`${process.env.REACT_APP_API_URL}/assets/copy`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      authorization: token,
    },
    body: JSON.stringify({
      subdomain,
    }),
  });
};

const onSchoolChange = (
  id: string,
  onChange: (editor: SchoolDocument) => void
) => {
  return firestore
    .collection('schools')
    .doc(id)
    .onSnapshot((snap) => {
      onChange((snap.data() as unknown) as SchoolDocument);
    });
};

export const schoolServices = {
  getSchools,
  createSchool,
  updateSchool,
  updateAssets,
  copyAssetsLive,
  watchSchool: onSchoolChange,
};

export type SchoolServices = typeof schoolServices;
