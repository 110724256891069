import React, { useEffect, useState } from 'react';
import { FormActions } from 'src/components/molecules/FormActions';
import { FormSection } from 'src/components/molecules/FormSection';
import { SwitchInput } from 'src/components/molecules/SwitchInput';
import { SchoolConfig } from 'src/types/schools';
import { updateFieldsInObject } from 'src/utils/objects';
interface FormInputs {
  content_menu: boolean;
  custom_tour: boolean;
  off_campus_locations: boolean;
  hide_logo: boolean;
}

type FeaturesFormProps = {
  config: SchoolConfig;
  onSave: (data: SchoolConfig) => Promise<void>;
};

export const FeaturesForm: React.FC<FeaturesFormProps> = ({
  config,
  onSave,
}) => {
  const [saving, setSaving] = useState(false);
  const [features, setFeatures] = useState<FormInputs>(toFormState(config));

  const onSubmit = async (data: FormInputs) => {
    setSaving(true);
    const updatedConfig = updateFieldsInObject(data, config) as SchoolConfig;
    await onSave(updatedConfig);
    setSaving(false);
  };

  useEffect(() => {
    setFeatures(toFormState(config));
  }, [config]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(features);
        return false;
      }}
    >
      <FormSection title="App Features">
        <SwitchInput
          onChange={(e) =>
            setFeatures({ ...features, hide_logo: e.target.checked })
          }
          label="Hide Logo"
          tip="Hides the school logo from the app's header."
          value={features?.hide_logo}
        />
        <SwitchInput
          onChange={(e) =>
            setFeatures({ ...features, content_menu: e.target.checked })
          }
          label="Content Menu"
          tip="Adds a menu tab to navigate various university resources"
          value={features?.content_menu}
        />
        <SwitchInput
          onChange={(e) =>
            setFeatures({ ...features, custom_tour: e.target.checked })
          }
          label="Custom Tours"
          tip="Allow users to create custom tours"
          value={features.custom_tour}
        />
        <SwitchInput
          onChange={(e) =>
            setFeatures({ ...features, off_campus_locations: e.target.checked })
          }
          label="Off Campus Locations"
          tip="Display off campus locations in the explore tab"
          value={features.off_campus_locations}
        />
      </FormSection>
      <FormActions
        onReset={() => setFeatures(toFormState(config))}
        disabled={saving}
        loading={saving}
      />
    </form>
  );
};

type ToFormState = (config?: SchoolConfig) => FormInputs;
const toFormState: ToFormState = (config) =>
  config
    ? {
        content_menu: config.features.content_menu,
        custom_tour: config.features.custom_tour,
        off_campus_locations: config.features.off_campus_locations,
        hide_logo: config.features.hide_logo,
      }
    : {
        content_menu: false,
        custom_tour: false,
        off_campus_locations: false,
        hide_logo: false,
      };
