// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.secondary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.75,
    },
    '&$track': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  switchLabel: {
    fontWeight: 500,
  },
  track: {
    backgroundColor: theme.palette.secondary.main,
  },
  checked: {},
  label: {
    display: 'block',
    fontSize: '1rem',
    fontWeight: 500,
    color: 'inherit',
  },
  tip: {
    display: 'block',
    fontWeight: 'normal',
    color: 'rgba(0,0,0,0.5)',
  },
}));
