import AddIcon from '@material-ui/icons/Add';
import { RouteComponentProps } from '@reach/router';
import React, { KeyboardEvent, useState } from 'react';
import { Button } from 'src/components/atoms/Button';
import { Header } from 'src/components/organisms/Header';
import { CreateSchoolDialog } from 'src/components/organisms/modals/CreateSchoolDialog';
import { SchoolList } from 'src/components/organisms/SchoolList';
import { SCHOOL_RESULTS_PER_PAGE } from 'src/config/settings';
import useStyles from './index.styles';

export const Schools: React.FC<RouteComponentProps> = () => {
  const styles = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const onSearchSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  return (
    <>
      <Header
        showSearch
        pageTitle="Schools"
        searchProps={{
          onChange: (e) => setSearchValue(e.currentTarget.value),
          value: searchValue,
          onKeyDown: onSearchSubmit,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          className={styles.addSchoolButton}
          startIcon={<AddIcon />}
          onClick={() => setOpenCreateDialog(true)}
        >
          School
        </Button>
      </Header>
      <SchoolList
        query={searchValue}
        resultsPerPage={SCHOOL_RESULTS_PER_PAGE}
      />
      <CreateSchoolDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
      />
    </>
  );
};
