import classnames from 'classnames';
import { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import React, { HTMLAttributes } from 'react';
import useStyles from './index.styles';

type InputProps = {
  options?: CleaveOptions;
  className?: string;
} & HTMLAttributes<HTMLInputElement>;

export const Input: React.FC<InputProps> = ({ className, ...props }) => {
  const styles = useStyles();
  return (
    <Cleave
      options={{ delimiter: '' }}
      {...props}
      className={classnames(styles.input, className)}
    />
  );
};
