// @flow
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  disclaimer: {
    maxWidth: 350,
    marginBottom: theme.spacing(3),
  },
  instructionLink: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    fontWeight: 500,
    cursor: 'pointer',
  },
}));
