// @flow
import { makeStyles } from '@material-ui/core/styles';

type CardProps = {
  primaryColor?: string;
  secondaryColor?: string;
};
export default makeStyles((theme) => ({
  skeletonBackground: {
    backgroundColor: '#F6F6F6 !important',
    boxShadow: 'none !important',
  },
  placeholder: {
    backgroundColor: '#EAEAEA !important',
  },
  card: {
    width: 300,
    height: 300,
    backgroundColor: (props: CardProps) =>
      props.primaryColor || theme.palette.primary.main,
    borderRadius: 5,
    boxShadow: '0px 6px 35px 0px rgba(0,0,0,0.25)',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
    flex: 1,
  },
  logo: {
    height: 50,
    marginBottom: theme.spacing(2),
  },
  name: {
    fontWeight: 500,
    textAlign: 'center',
  },
  url: {
    color: (props: CardProps) =>
      props.secondaryColor || theme.palette.secondary.main,
    textDecoration: 'none',
    textAlign: 'center',
  },
  editButton: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '0 0 5px 5px',
    width: '100%',
    height: 65,
    backgroundColor: (props: CardProps) =>
      props.secondaryColor || theme.palette.secondary.main,
    color: (props: CardProps) =>
      props.secondaryColor
        ? theme.palette.getContrastText(props.secondaryColor)
        : 'black',
    padding: `0 ${theme.spacing(2)}px`,
  },
  editButtonLabel: {
    fontWeight: 500,
  },
}));
