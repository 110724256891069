import { makeAutoObservable, runInAction } from 'mobx';
import { EditorServices } from 'src/services/editors';
import { RootStore } from 'src/stores';
import { EditorDocument } from 'src/types/editors';

export class EditorStore {
  editors: Editor[] = [];
  loadError = '';
  loading = false;
  rootStore: RootStore;
  services: EditorServices;

  constructor(rootStore: RootStore, services: EditorServices) {
    makeAutoObservable(this, {
      rootStore: false,
      services: false,
    });
    this.rootStore = rootStore;
    this.services = services;
  }

  async fetchCurrentEditor() {
    try {
      this.loading = true;
      const editors = await this.services.getEditors();
      this.editors = editors.map((editor) => new Editor(editor, this));
    } catch (err) {
      console.error(err);
      this.loadError = err.message;
    } finally {
      this.loading = true;
    }
  }

  async fetchEditors() {}
}

export class Editor {
  data: EditorDocument | undefined;
  editorStore: EditorStore;

  constructor(editor: EditorDocument, editorStore: EditorStore) {
    makeAutoObservable(this, {
      editorStore: false,
    });
    this.editorStore = editorStore;
    this.data = editor;

    editorStore.services.watchEditor(editor.id, (editor) => {
      runInAction(() => {
        this.data = editor;
      });
    });
  }
}
